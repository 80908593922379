import React from "react"
import { graphql } from "gatsby"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import OpeningNews from "../components/openingNews/openingNews"
import ArticleNews from "../components/articleNews/articleNews"
import ImageNewsBg from "../images/JWC_news-opening.jpg"

function PageNews({ data }) {
  return (
    <Layout>
      <SEO title="News" />


      {/* Debug */}
      {/*<pre>{JSON.stringify(data.allWordpressWpPressrelease.edges[0].node, null, 4)}</pre>*/}

      {/* <OpeningNews
        background={{
          image: data.allWordpressWpPressrelease.edges[0].node.featured_media
            ? data.allWordpressWpPressrelease.edges[0].node.featured_media
                .source_url
            : "https://via.placeholder.com/1920x1080",
        }}
        title={data.allWordpressWpPressrelease.edges[0].node.title}
        excerpt={data.allWordpressWpPressrelease.edges[0].node.acf.excerpt}
        link={{
          url: data.allWordpressWpPressrelease.edges[0].node.acf.linkUrl,
          name: data.allWordpressWpPressrelease.edges[0].node.acf.linkName,
        }}
      /> */}

      {/* <OpeningNews
        background={{ image: ImageNewsBg }}
        title={"If we are to preserve culture we must continue to create it."}
      /> */}

      {/* Debug */}
      {/*<pre>{JSON.stringify(data, null, 4)}</pre>*/}

      <section className={"marginPage"} style={{ paddingBottom: '100px' }}>
        <div className={"flex center"}>
          <div className={"col col-0"} style={{ display: 'none' }}></div>
          {/* Map */}
          <>
            {/* .slice(1) remove first element */}
            {data.allWordpressWpPressrelease.edges.slice(1).map(({ node }) => (
              <ArticleNews
                title={node.title}
                excerpt={node.acf.excerpt}
                link={{
                  name: node.acf.linkName,
                  url: node.acf.linkUrl,
                }}
                image={{
                  url: node.featured_media
                    ? node.featured_media.source_url
                    : "",
                }}
              />
            ))}
          </>
        </div>
      </section>
    </Layout>
  )
}
export default PageNews

export const query = graphql`
  {
    allWordpressWpPressrelease(sort: { fields: [date], order: [DESC] }) {
      edges {
        node {
          date
          wordpress_id
          title
          acf {
            featured
            excerpt
            linkName
            linkUrl
          }
          featured_media {
            source_url
          }
        }
      }
    }
  }
`
