import React from "react"
//import { Link } from "gatsby"
//import PropTypes from "prop-types"

import styles from "./openingNews.module.scss"

function OpeningNews(props) {
  return (
    <>
      <div
        className={'bg bg-img ' + styles.openingNews}
        style={{
          backgroundImage: "url(" + props.background.image + ")",
        }}
      >
        <div className={'flex ' + styles.content}>
          <div className={'col col-4 col-m-6 col-s-10 offset-2 offset-s-2'}>
            <h2
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: props.title }}
            ></h2>
          </div>
        </div>
      </div>
    </>
  )
}
export default OpeningNews
